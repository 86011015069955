import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import Header from '../components/header';
import Footer from '../components/footer';
import Contact from '../components/contact';
import SEO from "../components/seo";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      cover: true,
      targetVideo: "",
    }
  }

  hoverOn = (id) => {
    console.log("mouse over" + id);
    this.setState({
      cover: false,
      targetVideo: id,
    })
  }

  hoverOff = (id) => {
    console.log("mouse off" + id);
    this.setState({
      cover: true,
      targetVideo: id,
    })
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="服務項目" />
        <Header />
        <Contact />

        <section>
          <div className="video-banner">
            <video 
              autoPlay
              muted
              loop
              style={{ height:"100vh", width:"100%", objectFit:"cover", position:"absolute" }}
            >
              <source src="/static/videos/products_background_oumsbh.mp4" type="video/mp4"></source>
            </video>
            <div className="banner-second-main" >
              <h1 className="main-banner-title">實現<br />超越想像的可能</h1>
            </div>
          </div>
        </section>

        <div className="page-content">
          <section className="section">
            <div className="container w-container">
              <div className="w-row">
                <div className="w-col w-col-12 w-col-stack">
                  <div className="w-dyn-list">
                    {data.allStrapiService.edges.map(document => (
                    <div key={document.node.id}> 
                      {(document.node.language === "Chinese") ?
                      <div className="w-dyn-items">
                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                          <ReactMarkdown source={document.node.description} />
                        </ScrollAnimation>
                      </div> : <span />}
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section more-padding" style={{ background:"#232323"}}>
            <div className="container w-container">
              <h3 className="invert-text-color" style={{ marginBottom:"1em" }}>防偽效果</h3>
              {data.allStrapiMotion.edges.map(document => (
                <div key={document.node.id}>
                  {(document.node.label === "Security" && document.node.language === "Chinese") ?
                  <div className="w-row">
                    <div className="w-col w-col-4 w-col-stack">
                      <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        <div onMouseEnter={() => this.hoverOn(document.node.id)} onMouseLeave={() => this.hoverOff(document.node.id)}>
                          <ReactPlayer
                            url={document.node.video_url.replace("/upload/", "/upload/c_scale,h_250,q_auto:best,w_250/")}
                            playing
                            loop
                            muted
                            width='250px'
                            height='250px'
                            preloading="true"
                            playbackRate={2}
                          />
                          { this.state.cover === false && this.state.targetVideo === document.node.id 
                            ? <div className="security-video-cover" style={{ visibility:"hidden", opacity: 0 }}> 
                                <Img fluid={document.node.preview.childImageSharp.fluid} />
                              </div>
                            : <div className="security-video-cover">
                                <Img fluid={document.node.preview.childImageSharp.fluid} />
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="w-col w-col-8 w-col-stack">
                      <h4 className="invert-text-color">{document.node.name}</h4>
                      <ReactMarkdown source={document.node.description} />
                    </div>
                  </div> : <span /> }
                </div>
              ))}
              <div className="w-row" style={{ minHeight:"235px" }}>
                <div className="w-col w-col-4 w-col-stack">
                  <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="security-video-cover">
                      <img alt="" height="250px" width="250px" src="/static/images/ghosting_black_uasmne.jpg" />
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-8 w-col-stack">
                  <h4 className="invert-text-color">點光源*</h4>
                  <p><i>在點光源的照射下再現出隱藏的文字或圖案</i></p>
                </div>
              </div>
              <div className="w-row" style={{ minHeight:"235px" }}>
                <div className="w-col w-col-4 w-col-stack">
                  <div className="product-wrapper w-col w-col-3 w-col-medium-4 w-col-small-6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="security-video-cover">
                      <img alt="" height="250px" width="250px" src="/static/images/hidden_image_black_gedus5.jpg" />
                    </div>
                  </div>
                </div>
                <div className="w-col w-col-8 w-col-stack">
                  <h4 className="invert-text-color">激光再現*</h4>
                  <p><i>在點激光的照射下再現出隱藏的文字或圖案</i></p>
                </div>
              </div>
              <p>*防偽效果需使用特殊閱讀設備方能程現。</p>
            </div>
          </section>

          <section className="section">
            <div className="container w-container">
              <div className="w-col w-col-12 w-col-stack">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <div className="w-row">
                    <h2>新創設計</h2>
                    <h3>光群千變萬化的產品選擇為您的創意開啟無限的可能，立體視覺效果的特殊材料將引領您進入全新領域。我們提供標準系列的樣品協助您尋找靈感，也能根據您的需求提供產品設計及打樣。</h3>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <img alt="" style={{ padding:"5%"}} src="/static/images/dpittszcn4ldhqsi8vtc.jpg" />
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <h3>光群致力於為新一代設計師及學生提供技術交流。歡迎設計工作室及各大院校與我們聯絡，一起舉辦研討會分享雷射材料應用與印刷等主題。</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                  <div className="w-row" style={{ marginTop: "100px" }}>
                    <h2>專業技術支援</h2>
                    <h3>欲了解更多產品特性、加工或印刷上的建議，光群的專業團隊將協助解答您的問題並提供建議，也可以根據您的需求推薦合適的合作廠商，歡迎與我們聯繫。</h3>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </Layout>
    )
  }
}
export default ServicePage;

export const pageQuery = graphql`  
  query ServiceQuery {
    allStrapiService {
      edges {
        node {
          id
          description
          language
        }
      }
    }
    allStrapiMotion (sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          name
          video_url
          label
          language
          description
          preview {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
